import React from "react";
import transactionDone from '../images/transactionDone.webp'
import { useParams } from "react-router-dom";

const PaymentDetails = () => {

  const params = useParams();
  console.log(params);

  return (
    <div className=" my-20 items-center flex justify-center">
    <div
    style={{ width: "90%", height: "500px", alignItems: "center" }}
    className=" items-center flex flex-col justify-center gap-4 border-2 border-[#646464] ">
    <img src={transactionDone} alt=""  height={200} width={200}/>
        <span className="flex flex-col gap-2 justify-center items-center text-center">
        <span className="text-2xl font-semibold">
            Transaction Completed 
        </span>
        <span className="text-lg text-[#646464]">Thank You</span>
        </span>
        <span className=" text-lg">Please return to App Screen</span>
        </div>
    </div>
  );
};

export default PaymentDetails;
