import React, { useState, useEffect } from 'react';
import '../css/blog.css';
import blog from '../images/blog.jpg';
import blogimg from '../images/blogimg.jpg';
import author from '../images/author.svg';
import search from '../images/search.png';
import coffee from '../images/coffee.png';
import { useParams } from 'react-router-dom';

function Blog() {
    const { id } = useParams();
    const [paragraph, setParagraph] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [writer, setWriter] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(id);
        fetch('https://payment.umla.in/api/v1/umla/user/getPara', {
            method: 'POST',
            body: JSON.stringify({ blogId: id }),
            headers: { 'Content-type': 'application/json; charset=UTF-8' }
        })
        .then(response => response.json())
        .then(data => {
            setParagraph(data.blogData.paragraph);
            setImage(data.blogData.image);
            setTitle(data.blogData.title);
            setWriter(data.blogData.writer);
            console.log("the writer name is ");
            console.log(data.blogData.writer);
            console.log(writer);
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching paragraph:', error);
            setLoading(false);
        });
    }, [id]);

    return (
        <>
            <div style={{ marginTop: '68px', marginRight: '0', marginLeft: '0' }}>
                <img style={{ width: '98.9vw' }} src={blog} alt='Blog banner' />
            </div>
            <div className='blogPageWrapper d-flex flex-wrap align-items-center justify-content-between'>
                <div className='blogPage'>
                    <p style={{ fontSize: '24px', fontWeight: '700' }}>{title}</p>
                    <img className='blogImage' src={image} alt='Blog' />
                    <div className='blogText' dangerouslySetInnerHTML={{ __html: paragraph }} />
                </div>
                <div className='d-flex gap-3 flex-column align-items-start justify-content-start'>
                    <div className='author d-flex gap-3 align-items-center justify-content-start'>
                        <img style={{ height: '80px', width: '70px' }} src={author} alt='Author' />
                        <div style={{ marginTop: '15px' }}>
                            <p style={{ fontSize: '15px', fontWeight: '600', marginTop: '-10px' }}>Written By</p>
                            <p style={{ color: '#5E5E5E', fontWeight: '600', fontSize: '15px', marginTop: '10px' }}>{writer}</p>
                            <p style={{ color: '#676767', fontSize: '10px', marginTop: '8px' }}>Date:- 07-Jan-2024</p>
                        </div>
                    </div>
                    <div className='suggestions'>
                        <p style={{ fontSize: '14px', fontWeight: '600', marginLeft: '14px' }}>You May Also Like</p>
                        <div className='d-flex flex-column gap-1' style={{ lineHeight: '15px', width: '200px', marginLeft: '15px' }}>
                            <a style={{ fontSize: '12px', color: '#5F5F5F', fontWeight: '600', textDecoration: 'underline' }} href='/blog/6'>Date Safer with Umla</a><br />
                            <a style={{ fontSize: '12px', color: '#5F5F5F', fontWeight: '600', textDecoration: 'underline' }} href='/blog/8'>See Who Likes You</a><br />
                            <a style={{ fontSize: '12px', color: '#5F5F5F', fontWeight: '600', textDecoration: 'underline' }} href='/blog/4'>Find Your Perfect Match</a><br />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog;
