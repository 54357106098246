import React from "react";

const TermsAndCon = () => {
  return (
    <div
      style={{ marginTop: "13vh", marginBottom: "5vh" }}
      className="flex items-center justify-center "
    >
      <div style={{ width: "85%" }} className="text-lg">
        <h1 className="text-2xl font-bold my-4 flex items-center">
          UMLA Terms and Conditions of Use{" "}
        </h1>

        <p className="mb-4">
          Welcome to the Terms and Conditions of Use (“Terms”) for UMLA. This is
          a legally binding agreement between you and UMLA Technologies Private
          Limited (“UMLA,” “we,” “us,” or “our”) governing your use of the UMLA
          platform, website, or application (collectively, the “Platform”).
          Before you use the Platform, please take the time to read these Terms
          carefully. By accessing, viewing, or using the Platform, you
          acknowledge that you have read, understand, and agree to be bound by
          these Terms.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Subscription Terms
        </h1>

        <p className="mb-4">
          If you choose to subscribe to our services for a specified term (the
          “Initial Term”), your subscription will automatically renew for
          additional periods equal to the Initial Term at UMLA's then-current
          fees for such services unless you cancel your subscription in
          accordance with our cancellation policy outlined in Section 5 of these
          Terms.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Dispute Resolution
        </h1>

        <p className="mb-4">
          Please pay special attention to Section 13 of these Terms, which
          describes the dispute resolution process. It includes an arbitration
          agreement which, except for specified exceptions, requires any
          disputes between you and UMLA to be submitted to binding and final
          arbitration. By not opting out of the arbitration agreement in
          accordance with Section 13, you agree that (1) you can only bring
          claims against us on an individual basis, not as a plaintiff or class
          member in any class or representative action or proceeding, and (2)
          you are waiving your right to seek relief in a court of law and to
          have a jury trial on your claims. Terms.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Cancellation and Refund Policy
        </h1>

        <p className="mb-3">
          Users who are Purchase premium subscriptions of Umla can not cancel
          the Umla subscription. A flat fee of Rs. 30 would be deducted on
          refund of a coupon. Remember, by using our Platform, you are affirming
          that you are consenting to these Terms. If you do not agree to these
          Terms, do not use the Platform. We recommend that you keep a copy of
          these Terms for your records. For any further queries or assistance,
          please contact UMLA Technologies Private Limited customer service.
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href="umlacare@gmail.com"
            target="_blank"
          >
            (umlacare@gmail.com)
          </a>
        </p>

        <p className="mb-4">
          Thank you for choosing UMLA to foster new connections and shared
          experiences.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Umla Coupon/ Deal Terms
        </h1>

        <p className="mb-3 text-black font-semibold">
          UMLA Community Guidelines
        </p>

        <p className="mb-2">
          Account Registration To access and use our Platform, you must register
          for an account (“Account”). To create an Account, you are required to:
        </p>

        <ul
          style={{ listStyleType: "disc", marginLeft: "30px" }}
          className="mb-4"
        >
          <li>
            Be at least 18 years old or of the age of majority that legally
            permits you to enter a contract under the laws of your home country.
          </li>
          <li>
            Have the legal capacity to use the Platform in accordance with the
            laws of your home country.
          </li>
        </ul>

        <p className="mb-4 ">
          You may not use another person's Account nor share your Account with
          others without consent.
        </p>

        <p className="mb-4 ">
          Your Account can be deleted at any time through the 'Settings' page
          when logged in by clicking on the 'Delete account' link. Deletion is
          immediate, but some content may take time to be fully removed from the
          Platform as described in our Privacy Policy. Re-activation of your
          Account using the same credentials during this time may result in
          restoration of the original Account.
        </p>

        <p className="mb-4 ">
          Our systems and moderation team actively monitor accounts and messages
          for any breaches of our Terms. We reserve the right to terminate or
          suspend any Account, restrict access to the Platform, or utilize any
          available operational, technological, or legal methods to enforce the
          Terms, including blocking specific IP addresses, at any time without
          prior notice and liability. This right extends to terminations or
          suspensions based on inappropriate or improper conduct as solely
          determined by us.
        </p>

        <p className="mb-3 ">
          Unauthorized access to or use of non-public areas of the Platform or
          our systems is strictly prohibited.
        </p>

        <p className="mb-2">
          Content on the Platform You will encounter three types of content on
          the Platform:
        </p>

        <ul
          style={{ listStyleType: "disc", marginLeft: "30px" }}
          className="mb-3"
        >
          <li>Content that you provide (“Your Content”).</li>
          <li>Content provided by other members (“Member Content”).</li>
          <li>
            Content provided by UMLA Technologies Private Limited, which
            includes databases and/or software (“Our Content”).
          </li>
        </ul>

        <p className="mb-3 text-black font-semibold">
          Restrictions on Content:
        </p>
        <p className="mb-3 ">
          Our community thrives on respect and the ability to express oneself.
          However, to maintain the integrity of our community, we do not allow
          content that:
        </p>

        <ul
          style={{ listStyleType: "disc", marginLeft: "30px" }}
          className="mb-3"
        >
          <li>
            Is offensive or intended to harass, upset, embarrass, or alarm
            others.
          </li>
          <li>
            Is obscene, pornographic, violent, or offensive to human dignity.
          </li>
          <li>
            Is abusive, threatening, promotes racism, sexism, hatred, or
            bigotry.
          </li>
          <li>
            Encourages illegal activities, including terrorism, inciting racial
            hatred, or criminal offence commission.
          </li>
          <li>Is defamatory or libelous.</li>
          <li>
            Pertains to commercial activities (sales, competitions, advertising,
            links to other websites, or premium line telephone numbers).
          </li>
          <li>Constitutes "junk" mail or "spam".</li>
          <li>
            Contains spyware, adware, viruses, or other harmful code designed to
            disrupt the functionality of software/hardware.
          </li>
          <li>
            Infringes on any third-party rights, including intellectual property
            and privacy rights.
          </li>
          <li>Shows another person without their consent.</li>
          <li>
            Is harmful to minors or impersonates any person, including false
            identification.
          </li>
        </ul>

        <p className="mb-4">
          We adopt a zero-tolerance policy towards this kind of prohibited
          content.
        </p>

        <p className=" text-black font-semibold mb-3">
          User Content Responsibilities
        </p>

        <p className="mb-4">
          When you post content on Umla APP ("Your Content"), it should align
          with the community guidelines which are updated periodically. You are
          responsible for Your Content and will be held accountable for any
          claims related to it. This includes defending and indemnifying Umla
          from claims in connection with Your Content.
        </p>

        <p className=" text-black font-semibold mb-3">
          Personal Information and Privacy
        </p>

        <p className="mb-4">
          You should not display personal or banking information on your profile
          or to other users. Any personal information you share with others is
          at your own risk. Umla recommends exercising caution when disclosing
          personal information online, similar to how you would in any other
          public scenario.
        </p>

        <p className=" text-black font-semibold mb-3">
          Content Sharing and Licensing
        </p>

        <p className="mb-3">
          Your Content will be publicly visible, so only share what you’re
          comfortable with others seeing. By posting Your Content, you grant
          Umla a worldwide, non-exclusive, royalty-free license to use that
          content in various ways. This includes modifying and incorporating it
          into other works for any public display, in any format or medium. Umla
          can also assign or sublicense this licence.
        </p>

        <p className="mb-4">
          Umla is not required to store Your Content. If it’s important, keep a
          backup.
        </p>

        <p className=" text-black font-semibold mb-3">Member Content</p>

        <p className="mb-4">
          Content from other users is their responsibility, and you may not use
          it beyond the app’s intended purposes, such as for commercial use or
          harassment. Misuse of other users’ information may result in account
          termination.
        </p>

        <p className=" text-black font-semibold mb-3">
          Our Content and Intellectual Property
        </p>

        <p className="mb-4">
          The rest of the content on Umla, apart from Your Content and Member
          Content, is owned by Umla This includes all graphics, interfaces,
          trademarks, and other intellectual property on the app.Umla grants you
          a non-exclusive, limited license to access and use this content in
          line with the app's functionalities, but not for commercial
          exploitation.
        </p>

        <p className=" text-black font-semibold mb-3">Content Moderation</p>

        <p className="mb-4">
          Umla does not routinely pre-screen content but reserves the right to
          remove or refuse any user content. This discretion also applies to
          content shared in direct messages between users.
        </p>

        <p className=" text-black font-semibold mb-3">Non-Transferable</p>

        <p className="mb-4">
          This voucher is strictly non-transferable and is intended for the
          recipient only.
        </p>

        <p className=" text-black font-semibold mb-3">Item Replacement</p>

        <p className="mb-4">
          In the event that the item mentioned in the voucher is unavailable at
          the venue, the venue partner reserves the right to replace it with
          another item of the same or equal value.
        </p>

        <p className=" text-black font-semibold mb-3">Venue Rules</p>

        <p className="mb-4">
          All rules and regulations set by the venue partner must be adhered to
          while redeeming the voucher.
        </p>

        <p className=" text-black font-semibold mb-3">Third-Party Payments</p>

        <p className="mb-4">
          Payments made through third-party platforms such as Dineout,
          Eazydiner, etc., will not be accepted for voucher redemption. Direct
          payments only.
        </p>

        <p className=" text-black font-semibold mb-3">
          Discretionary Discounts
        </p>

        <p className="mb-4">
          Venue partners may offer discretionary discounts. These are separate
          from the voucher and are at the sole discretion of the venue partner.
        </p>

        <p className=" text-black font-semibold mb-3">Time Limit</p>

        <p className="mb-4">
          The voucher is valid only up to 15 minutes beyond the scheduled time
          mentioned. If you arrive later than the stipulated time, the voucher
          may expire, and you may also lose your reservation.
        </p>

        <p className=" text-black font-semibold mb-3">Reservation</p>

        <p className="mb-4">
          It is the responsibility of the voucher holder to ensure they arrive
          on time to redeem their offer. Late arrivals may result in the
          forfeiture of the reservation.
        </p>

        <p className=" text-black font-semibold mb-3">Validity</p>

        <p className="mb-4">
          Vouchers are valid for a one-time use only and must be redeemed before
          the expiry date mentioned.
        </p>

        <p className=" text-black font-semibold mb-3">No Cash Value</p>

        <p className="mb-4">
          The voucher has no cash value and cannot be exchanged for cash or any
          other form of credit.
        </p>

        <p className=" text-black font-semibold mb-3">
          Modification & Cancellation
        </p>

        <p className="mb-4">
          UMLA reserves the right to modify or cancel the voucher at any time
          without prior notice.
        </p>

        <p className=" text-black font-semibold mb-3">Liability</p>

        <p className="mb-4">
          UMLA is not responsible for any disputes arising out of the voucher
          redemption at the venue partner's location.
        </p>

        <p className="mb-4">
          By redeeming this voucher, you agree to abide by these terms and
          conditions. Any violation may result in the forfeiture of the voucher
          and any associated benefits.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          PAYMENT TERMS AND CONDITIONS
        </h1>

        <p className="mb-3">
          By using UMLA's services, you may encounter products and services
          available for purchase within the application ("In-App Purchase").
          When you select to make an In-App Purchase, you are agreeing to any
          additional terms that are presented to you at the time of purchase,
          which are hereby incorporated into these terms by reference.
        </p>

        <p className="mb-4">
          In-App Purchases can be made through the following payment methods
          ("Payment Method"): (a) purchases through third-party platforms like
          the Apple App Store or Google Play Store ("Third Party Store"), or (b)
          payments via your credit card, debit card, or PayPal account processed
          by our third-party payment processor. By completing an In-App
          Purchase, you authorize UMLA to charge your selected Payment Method.
          Should we fail to process a charge via your chosen Payment Method, you
          agree to settle any outstanding amounts upon our request.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Subscriptions and Auto-Renewal:
        </h1>

        <p className="mb-4">
          UMLA may provide certain services such as automatically renewing
          subscriptions. Examples include a one-week, one-month, or three-month
          subscription ("Premium Services"). IF YOU PURCHASE A PREMIUM SERVICE
          WITH AUTOMATIC RENEWAL, IT WILL RENEW AT THE END OF EACH CYCLE AT
          UMLA'S CURRENT PRICE FOR SUCH SUBSCRIPTIONS UNLESS YOU CANCEL PRIOR TO
          THE END OF THE CURRENT PERIOD. Deleting your UMLA account or the
          application from your device does not constitute cancellation of your
          subscription.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Cancellation of Subscriptions:
        </h1>

        <p className="mb-4">
          Direct purchases from UMLA can be cancelled or the Payment Method
          changed via the payment settings in your profile. If purchased through
          a Third Party Store, cancellation must be processed through that store
          following their specific instructions. After cancellation, you may use
          your subscription until the end of the current period. There will be
          no prorated refunds, your subscription will not renew, and you will no
          longer have access to Premium Services after the end of the last paid
          period.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          Free Trials:
        </h1>

        <p className="mb-4">
          Signing up for a free trial and failing to cancel before the end of
          the trial will result in conversion into a paid subscription and the
          charge of your Payment Method at the then-current rate. To avoid
          charges, cancellation must occur before the end of the current period
          or free trial. Deleting your account or application does not cancel
          your free trial.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">Refunds:</h1>

        <p className="mb-4">
          All charges for In-App Purchases are generally non-refundable. No
          refunds or credits are provided for partially used periods, with the
          exception of specific conditions applicable to residents of certain
          states, where applicable law may dictate differing refund policies.
        </p>

        <p className=" text-black font-semibold mb-3">Refund Process:</p>

        <p className="mb-3">
          Eligible subscribers who wish to receive a refund must request one in
          addition to canceling. If your subscription is via an Apple ID, manage
          refunds through Apple. For a refund, visit iTunes, select your Apple
          ID, go to "Purchase history," locate the transaction, and choose
          "Report Problem". Alternatively, a request can be submitted at Apple's
          official support link.
        </p>

        <p className="mb-3">
          UMLA operates a global business, and prices may vary based on a
          multitude of factors. We reserve the right to modify pricing, limit
          quantities, discontinue services, and refuse any user's transaction
          without prior notice.
        </p>

        <p style={{ fontStyle: "italic" }} className="mb-4 ">
          These terms may be subject to changes and it is the responsibility of
          the user to stay updated with the latest terms as provided by UMLA.
        </p>

        <hr className="border-t border-2 border-[#1b1b1b]  mb-3"></hr>

        <p className="mb-4">
          Please note that the actual implementation of such terms will need to
          be in accordance with the legal and operational practices of your
          service and compliant with any applicable laws and regulations. It's
          also critical to ensure that all users are clearly informed about
          these terms and give their consent where required.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          PUSH NOTIFICATIONS AND LOCATION SERVICES
        </h1>

        <p className="mb-3">
          Umla may send you emails, text messages, push notifications, alerts,
          and other types of messages related to the App and/or Umla services,
          such as improvements, offers, products, events, and other promotions.
          Upon downloading the App, you will have the option to either enable or
          disable push notifications/alerts. Accepting will enable UMla to send
          automatic push notifications/alerts to your device. Should you later
          decide to opt-out of receiving push notifications/alerts from the App,
          you may do so through your device's notification settings. For opting
          out of other types of communications like emails or text messages,
          instructions will be provided within those messages, or you can send a
          request to our designated email address.
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href="umlacare@gmail.com"
            target="_blank"
          >
            (umlacare@gmail.com)
          </a>
        </p>

        <p className="mb-4">
          The App may offer content, products, services, and materials based on
          your location, for which the App will determine your location using
          methods such as GPS, Bluetooth, or other location-detecting software
          on your mobile device. Disabling these functions or not granting the
          App permission to access your location data will prevent access to
          location-dependent services. For details on how the App uses and
          retains your information, please review our Privacy Policy.
        </p>

        <p className=" text-black font-semibold mb-3">DISCLAIMER</p>

        <p className="mb-3">
          The App, the website, our content, and content provided by members are
          offered to you on an "as is" and "as available" basis without
          warranties of any kind, either express or implied, including but not
          limited to warranties of fitness for a particular purpose, title, or
          non-infringement. We do not guarantee compatibility with any matches
          and we do not guarantee uninterrupted, secure, or error-free operation
          of the App or website.
        </p>

        <p className="mb-3">
          If the law does not allow the exclusion of warranties as set out
          above, then we grant the minimum warranty required by law. No advice
          or information, regardless of whether it is written or oral, obtained
          by you from us shall create any warranty not explicitly stated in this
          section.
        </p>

        <p className="mb-3">
          We do not conduct criminal background checks on our members and we are
          not responsible for the conduct of any user. Your use of the App and
          interaction with other members is at your own risk.
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          LIMITATION OF LIABILITY
        </h1>

        <p className="mb-3">
          Umla, nor any of its affiliates, will be liable for any direct,
          indirect, incidental, consequential, special, or punitive damages,
          including but not limited to loss of data, income, profit, or
          goodwill, loss of or damage to property and claims of third parties
          arising from your access to or use of the App, the website, our
          content, or any member content, regardless of the cause of action.
        </p>

        <p className="mb-3">
          If you are dissatisfied with the App, the website, or any of the
          terms, conditions, rules, policies, guidelines, or practices, your
          sole and exclusive remedy is to discontinue using the App and website.
        </p>

        <p className="mb-3">
          The limitations, exclusions, and disclaimers herein apply to the
          maximum extent permitted by law, and the aggregate liability of Umla
          will not exceed one Ten thousand Rupees (Rs 10000). The limitations
          and exclusions reflect a fair allocation of risk. Some jurisdictions
          do not allow the limitation of liability for certain types of damages,
          so some of these limitations may not apply to you.
        </p>

        <p className="mb-3">
          Any liability arising from fraud or fraudulent misrepresentation, or
          any other liabilities that cannot be limited or excluded by law, are
          not affected by the above limitations. These terms are intended to be
          as broad and inclusive as permitted by the law of your jurisdiction
          and if any provision is found to be invalid or unenforceable, the
          remaining provisions will continue in full force and effect.
        </p>

        <p className=" text-black font-semibold mb-3">INDEMNIFICATION</p>

        <p className="mb-3">
          You are responsible for all actions taken and information posted on
          Umla by you. Consequently, you agree to indemnify, defend, and hold
          harmless Umla, along with our partners, licensors, affiliates,
          contractors, officers, directors, employees, representatives, and
          agents from any third-party claims, damages (both direct and
          consequential), actions, proceedings, demands, losses, liabilities,
          costs, and expenses (including legal fees) that arise due to:
        </p>

        <ul
          style={{ listStyleType: "disc", marginLeft: "30px" }}
          className="mb-4"
        >
          <li>Your negligent, reckless or intentionally wrongful actions;</li>
          <li>Your use of and access to the Umla App;</li>
          <li>The content you upload or submit to the App;</li>
          <li>Any violation of these Terms by you;</li>
          <li>Your infringement of any law or the rights of a third party.</li>
        </ul>

        <p className="mb-3">
          Umla reserves the right to assume the exclusive defense and control of
          any matter for which you are required to indemnify us and you agree to
          cooperate with our defense of these claims. You shall not settle any
          matter without the prior written consent of UMLA.
        </p>

        <p className="mb-3">
          This indemnification obligation does not apply to any actions or
          claims resulting from UMla's own conduct that is found to be grossly
          negligent, fraudulent, or intentionally wrongful.
        </p>

        <p className=" text-black font-semibold mb-3">
          COPYRIGHT INFRINGEMENT CLAIMS
        </p>

        <p className="mb-3">
          If you believe that any content on Umla infringes upon a copyright you
          hold, please forward a notification of claimed infringement to Umla's
          Copyright Agent including the following information:
        </p>

        <ul
          style={{ listStyleType: "disc", marginLeft: "30px" }}
          className="mb-4"
        >
          <li>
            A signature, physical or electronic, of a person authorized to act
            on behalf of the owner of the exclusive right that is allegedly
            infringed;
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed;
          </li>
          <li>
            A description of where the material you claim is infringing is
            located on the App;
          </li>
          <li>
            Your contact details, such as address, telephone number, and email
            address;
          </li>
          <li>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </li>
          <li>
            A statement, made under penalty of perjury, that the above
            information is accurate and that you are the copyright owner or
            authorized to act on the copyright owner's behalf.
          </li>
        </ul>

        <p className="mb-4">
          The notice should be sent to UMLA's designated Copyright Agent at the
          following email:
          <a
            className="mx-1"
            style={{ textDecoration: "underline" }}
            href="umlacare@gmail.com"
            target="_blank"
          >
            umlacare@gmail.com
          </a>
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          THIRD-PARTY APP STORE TERMS
        </h1>

        <p className="mb-3">
          When you download the Umla App from a Third-Party Store, additional
          terms and conditions may apply to you which are laid down by the
          Third-Party Store. In cases where these Terms are less restrictive or
          otherwise in conflict with the terms of the Third-Party Store, the
          more restrictive or conflicting terms of the Third-Party Store will
          take precedence but only with respect to the Umla App and the
          Third-Party Store.
        </p>

        <p style={{ marginLeft: "30px" }}>
          <p>
            The relationship established by these Terms is solely between you
            and Umla, and not with the Third-Party Store providers. Umla (not
            the Third-Party Store providers) is responsible for the App and its
            content.
          </p>
          <p>
            Maintenance and support for the App are not the responsibility of
            the Third-Party Store providers. Any product warranties not
            effectively disclaimed by these Terms will be the sole
            responsibility of Umla.
          </p>
          <p>
            Umla is responsible for addressing any claims by you or any third
            party relating to the App or your possession/use of the App,
            including, but not limited to: (i) product liability claims; (ii)
            any claim that the App fails to conform to any applicable legal or
            regulatory requirement; (iii) claims under consumer protection laws;
            and (iv) intellectual property infringement claims.
          </p>
          <p>
            The Third-Party Store providers and their subsidiaries are
            third-party beneficiaries of these Terms and may enforce these Terms
            against you.
          </p>
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          MISCELLANEOUS
        </h1>

        <p className="mb-3">
          Additional terms need to be addressed before you engage with Umla.
        </p>

        <p className="mb-3">
          Entire Agreement: The Terms, subject to amendments from time to time,
          constitute the full agreement between you and Umla, eclipsing all
          prior negotiations, representations, and understandings, except for
          the Privacy Policy. Fraudulent misrepresentation remains untouched by
          any limitations herein.
        </p>

        <p className="mb-3">
          Information Accuracy and Usage: Umla strives to ensure current,
          accessible, correct, and complete information is presented, yet it is
          provided "as is" and "as available." Umla offers no warranties or
          representations concerning the information on its platform, either
          express or implied. Engaging with Umla and its content is done at your
          own risk, and Umla disclaims liability for losses tied to data
          transmission, utilization, or content inaccuracies.
        </p>

        <p className="mb-3">
          User Responsibilities: You must take necessary precautions to avoid
          downloading harmful components from Umla. Recognize that uninterrupted
          or faultless service, prompt defect rectifications, and a platform or
          server free from malicious software are not guaranteed. Umla holds no
          responsibility for damages to your hardware, software, or technology
          stemming from security breaches or malware.
        </p>

        <p className="mb-3">
          Electronic Communications: Interactions between you and Umla may be
          conducted electronically, whether through use of the platform, email
          communications with Umla, or through notices posted on Umla. By these
          Terms, you (a) agree to accept communications from Umla in an
          electronic format; and (b) understand that all agreements, notices,
          disclosures, and other communications provided electronically by Umla
          meet any legal requirement that such communications would satisfy if
          they were in a physical writing form.
        </p>

        <hr style={{ border: "1px solid black", marginBottom: "12px" }}></hr>

        <p style={{ fontStyle: "italic" }} className="mb-4">
          Please note that these are simply suggestions to alter the text to be
          more suitable for "Umla." Depending on the actual services, platform,
          and legal requirements of Umla, additional specific adjustments may be
          necessary. Always have legal texts reviewed by a qualified
          professional to ensure compliance with relevant laws and regulations
        </p>

        <h1 className=" text-2xl font-bold text-black mt-5 mb-4">
          THE UMLA GROUP.
        </h1>

        <p className="mb-3">
          These Terms establish a legally binding agreement between you, the
          user ("you"), and the Umla Group ("we" or "us"). The Umla Group
          comprises various entities, including but not limited to Umla
          Technologies private Limited (a company registered in India)
        </p>

        <p className="mb-4">
          Please note that this is a hypothetical example and the company names
          and jurisdictions are used purely for illustrative purposes. If you
          are looking to create or rephrase legal content for an actual company,
          it's important to use accurate and specific legal names and
          incorporation details.
        </p>
      </div>
    </div>
  );
};

export default TermsAndCon;
