import React from "react";
import '../css/blogcard.css'

const Privacy = () => {
  return (
    <div
      style={{ marginTop: "14vh" }}
      className=" flex items-center justify-center "
    >
      <div style={{ width: "75%" }} className="text-lg">
        <h1 className=" text-3xl font-bold text-black mb-4">
          Privacy Policy of Umla{" "}
        </h1>

        <p className="mb-4  sm:text-lg md:text-lg lg:text-xl ">
          Welcome to the Umla Privacy Policy (“Policy”). This Policy explains
          how we gather, secure, and share your information, along with the
          individuals with whom we share it. We recommend reading this Policy
          alongside our Terms and Conditions.
        </p>

        <p className="mb-4">
          As you engage with the Umla mobile application ("App"), our websites,
          microsites (such as Umla.com), and digital services (including surveys
          and competitions) (collectively referred to as "Sites"), certain
          information about you is collected.
        </p>

        <p className="mb-4">
          Moreover, if you choose to use the App or Sites to share information
          with other users, including friends and contacts ("Users"), we may
          need to share your information at times.
        </p>

        <p className="mb-4">
          The App and Sites have a global reach, and your data will be
          transferred to and utilized in India, regardless of your residing
          country. This Policy details how we protect your personal data during
          international transfers, so please read it thoroughly!
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">Who We Are </h1>

        <p className="mb-4">
          The App and Sites are operated by the "Umla Technologies Private
          Limited" (also referred to as "we" or "us"), encompassing Umla
          Technologies Pvt. Ltd. The Umla Group comprises entities controlling
          personal information processed through the Umla App and Sites.
        </p>

        <p className="mb-4">
          The Umla Group has designated a Data Protection Officer, can reach 
          {/* <a
            className=" mx-2 underline text-[#646464]"
            href="mailto:umlahelp@gmail.com"
          >
            DPO@umla.com{" "}
          </a> */}
          <span> via email at:</span>
          <a
            className=" underline text-[#646464]"
            href="mailto:umlahelp@gmail.com"
          >
            [umlahelp@gmail.com].
          </a>
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Collection of Information{" "}
        </h1>

        <p className="mb-2">Registration Information:</p>

        <p className="mb-2">
          Upon downloading the App and creating an account ("Account"), we may
          gather specific data ("Registration Information") about you,
          including:
        </p>

        <ul className="ul-list text-xl text-black">
          <li>Name</li>
          <li>Username</li>
          <li>Email address</li>
          <li>Contact number</li>
          <li>Personal preferences</li>
          <li>Photographs</li>
          <li>Location</li>
          <li>Govt ID</li>
        </ul>

        <p className="my-2">
          Login data for linked social media accounts (e.g., Facebook,
          Instagram). For more information, refer to "Linking other Accounts to
          Umla" in section 7 below.
        </p>

        <p>
          You can modify this information by logging into Umla at any time
          (except for date of birth and location, which may be updated based on
          device settings). Ensure your account details are current. Profile
          data like your name, username, and personal preferences might be
          visible to other users.
        </p>

        <p className="my-2">
          For Users in India, data we collect aligns with categories of
          "Personal 'Information" as defined by applicable laws:
        </p>
        <ol className="text-lg text-black mb-4">
          <li className=" mb-1">
            <span style={{ marginRight: "8px" }}>A.</span>
            Identifiers, like name and location
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>B.</span>
            Personal information, including contact and financial details
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>C.</span>
            Characteristics protected under relevant laws, like gender identity,
            sexual orientation, and medical conditions
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>D.</span> Commercial
            information, such as transactions
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>E.</span> Biometric information
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>F.</span>
            Internet activity, including browsing history and interactions with
            our Sites and App
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>G.</span>
            Geolocation data
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>H.</span>
            Audio, visual content, like photos and videos
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>I.</span>
            Employment-related details, such as work history
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>J.</span>
            Non-public education information
          </li>
          <li className=" mb-1">
            {" "}
            <span style={{ marginRight: "8px" }}>K.</span>
            Derived inferences from above data for profile creation.
          </li>
        </ol>

        <p className="mb-4">
          We value your privacy and will use this reference to shape Umla's
          Privacy Policy according to your specific requirements.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Profile Information
        </h1>

        <p className="mb-4">
          We advise and encourage all our members, including you, to be cautious
          about the information you reveal on your profile. We discourage
          sharing email addresses, URLs, instant messaging details, phone
          numbers, full names or addresses, credit card details, national
          identity numbers, driver's license details, and sensitive information
          that might be misused.
        </p>

        <p className="mb-4">
          When you share information about yourself or communicate with other
          Users through messaging, the responsibility for the personal
          information you disclose lies with you. Refer to Section 4 for details
          on who can access your posts on Umla.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Profile Verification Information (Including Biometric Information)
        </h1>
        <p className="mb-4">
          To ensure safety, security, and a positive user experience, we require
          Users to verify their accounts. This may entail providing your phone
          number and carrying out photo verification, helping prevent robot
          activity and fake profiles that could be used for malicious purposes.
        </p>

        <p className="mb-4">
          If you opt to verify your profile photo, each submitted photo will be
          scanned, including potential facial recognition comparisons to your
          profile photo for identity verification. Verification photos are not
          displayed on your profile. We retain these scans for verification and
          record-keeping purposes for three years after your last interaction
          with us, or as needed. After the retention period, we securely delete
          the scans from our systems.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          {" "}
          We've tailored Umla's Privacy Policy to incorporate these
          considerations from your reference.{" "}
        </h1>

        <h1 className=" text-3xl font-bold text-black mb-4">
          {" "}
          Purchases Information{" "}
        </h1>

        <p className="mb-4">
          If you choose to purchase any of our premium offerings, we will
          securely process your payment information for fraud prevention and
          audit/tax compliance purposes.
        </p>
        <p className="mb-4">
          Umla employs automated decisions to prevent processing fraudulent
          payment transactions as part of our anti-fraud protocols. Our systems
          assess payment transactions to identify potential breaches of our
          Terms and Conditions of Use. Transactions meeting specific criteria
          indicating likely breaches and fraud are automatically blocked. Users
          affected by blocked transactions will be notified and can contest the
          decision with Umla.
        </p>
        <p className="mb-4">
          Please note that if your account is blocked for any reason, your
          accounts on other Umla platforms/applications may also be blocked as
          part of our anti-spam and anti-fraud measures.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          {" "}
          Geolocation Information
        </h1>

        <p className="mb-4">
          With your device location access, we collect WiFi access point and
          location data including longitude and latitude to offer personalized
          features. This information helps us tailor the App, making it easier
          for you to engage with other Users by showing locality information on
          profiles and suggesting nearby profiles.
        </p>

        <h1 className=" text-2xl font-bold text-black mb-4">
          {" "}
          If you granted Umla location access and wish to disable it:
        </h1>

        <p className="mb-4">
          iPhone app — settings, privacy, location services, Umla Android —
          settings, location, Umla, permissions, location Device Information
        </p>

        <p className="mb-4">
          When using the App, we may gather device details like unique
          identifiers, device model, and operating system for various purposes
          outlined in this policy. With your permission, the App might access
          your device's address book solely to add a contact.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">Links</h1>

        <p className="mb-4">
          We track your interactions with links on Umla, including third-party
          services, for aggregated click statistics and analytics.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">Messages</h1>

        <p className="mb-4">
          We review message content to identify trends, topics, and sentiments
          among Users. Personally identifying information is stripped from
          messages before review. User message content and derived information
          are not shared with third parties.
        </p>

        <p className="mb-4">
          We've incorporated relevant elements from your reference into Umla's
          Privacy Policy.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Umla Success Stories, Surveys, and Other Contributions
        </h1>

        <p className="mb-4">
          Occasionally, we conduct surveys for research purposes and may reach
          out to inquire if you're interested in participating. Additionally, we
          might contact you to gather feedback, testimonials, or for
          participation in marketing campaigns (e.g., if you've found a
          connection on Umla, we may ask if you'd like to be featured in our
          advertising). These surveys and campaigns are voluntary, and detailed
          information will be provided during contact. If you prefer not to be
          approached for surveys or marketing efforts, kindly contact our
          Customer Support Team via our Feedback Page
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          When You Contact Customer Support
        </h1>

        <p className="mb-4">
          When you reach out to our Customer Support team via our Feedback Page,
          we will receive your email address and may track your IP address,
          along with the information you provide to assist in resolving your
          query. Records of our communications, including complaints from you
          about other Users (or from other Users about you), will be retained
          for 6 years after your account is deleted.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Cookies and Similar Technologies
        </h1>

        <p className="mb-4">
          When you use our Sites or App, we may automatically collect personal
          data through cookies or similar technologies. Cookies are small files
          that can be placed on your device or browser to help us recognize and
          remember you.
        </p>

        <p className="mb-2">
          For comprehensive information on cookies, including their usage and
          the choices available to you, please refer to our Cookie Policy.
        </p>

        <p className="mb-4">
          {" "}
          We've adapted relevant aspects from your reference into Umla's Privacy
          Policy.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Use of Your Information
        </h1>

        <p className="mb-4">
          Our utmost priority is to ensure your experience with Umla is
          delightful, without any hitches. To create this experience, we may
          utilize your Registration and other data to:
        </p>

        <ol className="text-lg text-black mb-4">
          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>1.</span>
            Provide you with our services and features.
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>2.</span>
            Keep you updated with information about the App, including updates
            and new features.
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>3.</span>
            Customize the App/Sites and the content to cater to your
            preferences. Conduct research and analysis on your interaction and
            usage patterns. Test new technologies and processes for App/Sites
            enhancement.
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>4.</span>
            Resolve disputes, troubleshoot issues, and enforce our Terms &amp;
            Conditions. Investigate fraudulent activities, safeguard our legal
            rights, and enforce our Terms &amp; Conditions.
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>5.</span>
            Send you information about promotions and offers available (e.g.,
            specify types of goods/services/offers) through direct marketing or
            other communication methods, provided you've opted in for our
            communications. Email direct marketing will only be used with your
            consent during Account creation or via App Settings (you can
            withdraw from marketing anytime through App Settings or opt-out
            mechanisms provided in each message).
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>6.</span>
            Safeguard our Users and third parties from potential harm. Our
            Matching Algorithms
          </li>

          <li className=" mb-2">
            <span style={{ marginRight: "8px" }}>7.</span>
            We've designed matching algorithms to predict your compatibility
            with other users, showing you potential matches. Our matching
            algorithms employ the following data to predict compatibility and
            generate profile recommendations.
          </li>
        </ol>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Information in your profile
        </h1>

        <p className="mb-2">Activity in our App.</p>

        <p className="mb-2">Photo verification status.</p>

        <p className="mb-2">Device coordinates for proximity assessment.</p>

        <p className="mb-4">
          The legal basis for processing profile and proximity data is the
          necessity for contractual services under Article 6(1)(b) GDPR.
          Processing app activity data is based on our legitimate interests
          under Article 6(1)(f) GDPR, particularly our interest in offering
          personalized recommendations for your matches. If you've provided
          sensitive data in your profile (e.g., beliefs), processing is based on
          your explicit consent.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Matching Algorithms and Moderation Practices
        </h1>

        <p className="mb-2">
          At Umla, our matching algorithms are developed to assess compatibility
          between users, helping us connect you with like-minded individuals. We
          use the following data to generate profile recommendations and predict
          compatibility:
        </p>

        <p className="mb-2">
          Information from your profile. Activity within our app.
        </p>

        <p className="mb-2">Photo verification status.</p>

        <p className="mb-2">Device coordinates for proximity assessment.</p>

        <p className="mb-4">
          Processing profile and proximity data is necessary for providing our
          contractual services under Article 6(1)(b) GDPR. App activity data
          processing is based on our legitimate interests as per Article 6(1)(f)
          GDPR, specifically our aim to offer personalized recommendations for
          matching within the app. If sensitive data (e.g., beliefs) is provided
          in your profile, its processing relies on your explicit consent.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Moderation Practices
        </h1>

        <p className="mb-4">
          We employ automated systems and a moderation team to review accounts,
          photos, and profile content for possible breaches of our Terms and
          Conditions. If an account or message indicates potential violations,
          it may receive a warning, access restrictions, or be blocked. Users
          can contest such decisions by reaching out to us.
        </p>

        <h1 className=" text-3xl font-bold text-black mb-4">
          Any content not in accordance with our Terms and Conditions may result
          in termination or restricted access to your Account.
        </h1>

        <h1 className=" text-3xl font-bold mb-4">
          Lawful Basis for Data Processing
        </h1>

        <p className="mb-5">
          Umla operates in compliance with Indian data protection laws. We
          ensure transparency regarding our lawful basis for using your data, as
          outlined in the table below. Consent-based processing allows
          withdrawal at any time, while legitimate interests-based processing
          offers the right to object. Relevant sections of this Policy explain
          consent withdrawal or opt-out procedures (where applicable).
        </p>

        <div  style={{ overflowX: "auto", backgroundColor: "#F6F6F7" ,}} className="privacy-table">
          <table className=" border border-black w-fit">
            <thead className="border-2">
              <tr className="text-black text-xl text-center">
                <th className="pb-2 font-semibold my-2 border-2">
                  Purpose for Data Use
                </th>
                <th className="pb-2 font-semibold border-2">Data</th>
                <th className="pb-2 font-semibold border-2">Source</th>
                <th className="pb-2 font-semibold border-2">Legal Basis</th>
              </tr>
            </thead>

            <tbody className=" ">
              <tr className="border-2 text-center">
                <td className="border-2">Providing Umla networking service</td>
                <td className="border-2">
                  {" "}
                  Name, email address, date of birth, location
                </td>
                <td className="border-2">
                  Provided by you during registration; location data from your
                  device
                </td>
                <td className="border-2">Contractual necessity</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Facilitating networking opportunities
                </td>
                <td className="border-2">
                  {" "}
                  Optional profile info, including special categories (e.g.,
                  sexual preferences, religion), photos, etc.
                </td>
                <td className="border-2">Provided by you</td>
                <td className="border-2">
                  Contractual necessity &amp; legitimate interests
                </td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Identity verification and fraud prevention
                </td>
                <td className="border-2">
                  {" "}
                  Phone number, photo for profile verification, ID photo,
                  geolocation, device info
                </td>
                <td className="border-2">
                  {" "}
                  Provided by you or obtained from your device
                </td>
                <td className="border-2"> Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Processing premium service payments
                </td>
                <td className="border-2"> Payment card details</td>
                <td className="border-2">Provided by you</td>
                <td className="border-2">Contractual necessity</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Sending marketing information</td>
                <td className="border-2">
                  {" "}
                  Name, email address, postal address, Instagram handle, phone
                  number
                </td>
                <td className="border-2">Provided by you</td>
                <td className="border-2">Consent or legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Research, analysis, and testing</td>
                <td className="border-2">
                  Log and usage data, IP address, browser, pages accessed,
                  images, etc.
                </td>
                <td className="border-2">Provided by you</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Responding to queries</td>
                <td className="border-2">
                  {" "}
                  Email address, IP address, social media name, phone number
                </td>
                <td className="border-2">
                  Provided by you; IP address from device
                </td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Investigating and blocking users</td>
                <td className="border-2">
                  {" "}
                  Registration info, profile data, message content, IP, device
                  data
                </td>
                <td className="border-2">Provided by you or device</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Enabling third-party account login</td>
                <td className="border-2"> Data from Facebook and others</td>
                <td className="border-2">Obtained from third-party accounts</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Blocking payment transactions</td>
                <td className="border-2">
                  Name, IP address, email, mobile number, cardholder info,
                  payment details, user ID
                </td>
                <td className="border-2">
                  Provided by you or device; payment info from interactions
                </td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">Serving ads on Umla</td>
                <td className="border-2">
                  Location, gender, age, profile data
                </td>
                <td className="border-2">Provided by you or device</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Serving ads on third-party networks
                </td>
                <td className="border-2">
                  Site or app visit data, IP, age, gender, device ID
                </td>
                <td className="border-2">Provided by you or device</td>
                <td className="border-2">Consent</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Running surveys and marketing campaigns
                </td>
                <td className="border-2">Email address, cell phone number</td>
                <td className="border-2">Provided by you</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Enabling video/audio calls and media sharing
                </td>
                <td className="border-2">Usage data, images, video</td>
                <td className="border-2">Obtained from device</td>
                <td className="border-2">Legitimate interests</td>
              </tr>

              <tr className="border-2 text-center">
                <td className="border-2">
                  Defending legal claims and protecting rights
                </td>
                <td className="border-2">Relevant info</td>
                <td className="border-2">
                  Provided by you, device, or third parties
                </td>
                <td className="border-2">Legitimate interests</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Disclosure of Information
        </h1>

        <p className="mb-4">
          Umla is committed to safeguarding your Registration Information and
          personal data. However, there are limited circumstances where
          disclosure may occur, as described below:
        </p>

        <div style={{ overflowX: "auto", backgroundColor: "#F6F6F7" }}>
          <table className=" border border-black w-fit">
            <thead className="border-2">
              <tr className="text-black text-xl text-center">
                <th className="pb-2 font-semibold my-2 border-2">
                  Circumstances for Disclosure
                </th>
                <th className="pb-2 font-semibold border-2">Disclosed Data</th>
              </tr>
            </thead>

            <tbody>
              <tr className="border-2 text-center">
                <td className="border-2">Providing Umla networking service</td>
                <td className="border-2">
                  Registration Information, personal data, and other Categories{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Moderators</td>
                <td className="border-2">
                  Name, user registration details, profile data, message
                  content, photographs{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">
                  Payment Processing and Telecommunications Companies
                </td>
                <td className="border-2">
                  Cardholder name, cardholder address, card number, payment
                  amount, transaction date/time{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Law and Harm</td>
                <td className="border-2">
                  All personal data, including CCPA Categories, when legally
                  required to comply with court orders, protect safety, address
                  fraud, or safeguard rights and property{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Business Transfers</td>
                <td className="border-2">
                  All personal data, including CCPA Categories, in case of
                  business transitions, acquisitions, re-organizations, sales,
                  insolvency, or administration{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Marketing Services Providers</td>
                <td className="border-2">
                  Advertising identifier (Device ID), estimated location (based
                  on IP address), age, gender, data about site/app visit, hashed
                  email address for 'custom audiences'{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Anti-Spam and Anti-Fraud</td>
                <td className="border-2">
                  Email address, phone number, IP address, social network ID,
                  username, user agent string, transaction and payment data{" "}
                </td>
              </tr>
              <tr className="border-2 text-center">
                <td className="border-2">Aggregated Information</td>
                <td className="border-2">
                  Aggregated data including personal data (anonymized) for
                  industry analysis and demographic profiling{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          More Information About Disclosures
        </h1>

        <h4 className=" text-[#646464] mt-2">Service Providers</h4>

        <p className="mb-4">
          Umla collaborates with trusted third-party service providers to
          deliver specific functions and services ("Service Providers"). The
          selection of suppliers may vary based on user engagement with Umla's
          platforms and services, including the App and Sites. Here are some
          examples of the types of suppliers we engage:
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">Billing Services:</h4>

        <p className="mb-2">
          To facilitate the purchase of premium features, such as Google Play
          for payment processing.
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">
          Authentication Services:
        </h4>

        <p className="mb-2">
          For user account verification, like Instagram or other social media
          platforms for authentication.
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">
          Social Media Providers:
        </h4>

        <p className="mb-2">
          To enable users to link their Umla account with their social media
          accounts, such as Facebook, Instagram, or Spotify.
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">
          Product Improvement and Market Research:
        </h4>

        <p className="mb-2">
          We conduct customer surveys and market research using third-party
          platforms like Typeform and agencies like Kantar to enhance our
          products and services.
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">IT Services:</h4>

        <p className="mb-2">
          Some third-party software providers assist in our business operations,
          such as community management software (e.g., Khoros) for processing
          user inquiries received via social media.
        </p>

        <p className="mb-2">
          Rigorous due diligence is carried out before engaging Service
          Providers to ensure compliance with data protection and information
          security requirements. Only necessary personal data is shared, and
          measures are taken to ensure data is as non-attributable as possible.
          Contractual agreements enforce strict data retention limits and
          compliance with our data protection standards.
        </p>

        <h4 className=" text-lg text-[#646464] mt-2">IT Services:</h4>

        <p className="mb-2">
          Some third-party software providers assist in our business operations,
          such as community management software (e.g., Khoros) for processing
          user inquiries received via social media.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Marketing Services Providers
        </h1>

        <p className="mb-4">
          Umla collaborates with Marketing Services Providers (e.g., Facebook)
          to market and advertise the App and services on third-party platforms
          and measure ad campaign effectiveness. For instance, these providers
          help us:
        </p>

        <p className="mb-2">Exclude Umla users from new user ad campaigns.</p>

        <p className="mb-1">
          Display Umla ads to those who visited the App/Sites but haven't
          created an account. Create a Lookalike Audience with characteristics
          similar to yours.d campaign effectiveness. For instance, these
          providers help us:
        </p>

        <p className="mb-1">
          Include users in custom audiences for targeted content. Limited
          personal data is shared with these providers, including
        </p>

        <p className="mb-4">
          Advertising identifier associated with your device. Estimated location
          based on your IP address
        </p>

        <p className="mb-1">Age and gender.</p>

        <p className="mb-1">
          Data about your App/Sites visit and actions taken (e.g., downloads,
          account creation).
        </p>

        <p className="mb-1">
          Hashed version of your email address for 'custom audiences'. Hashing
          encrypts data for privacy, preventing it from being traced back to the
          original email address
        </p>
        <p className="mb-4">
          Please refer to our Cookie Policy for information about tracking
          technologies and managing your preferences.
        </p>

        <p className="mb-4">
          These third parties may use collected data for their own purposes,
          including aggregating it with other data for advertising-related
          services offered to other clients.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          <span>4.</span>
          What Others May See About You
        </h1>

        <p className="mb-1">
          {" "}
          We believe our Users are amazing and want to provide features that let
          you showcase your awesomeness to the world. Our App is designed to
          facilitate connections and interactions among Users.
        </p>

        <p className="mb-4">
          When using Umla, it's important to understand that anything you post
          or submit on the App might be publicly visible and accessible by both
          App Users and non-users. We urge Users to exercise caution when
          sharing information that could potentially become public.
        </p>

        <p className="mb-1">
          Please be mindful of sharing sensitive details on your profile, such
          as religious affiliation or health information. You can also choose to
          include sensitive information in your profile using Umla badges, such
          as your political or religious preferences.
        </p>

        <p className="mb-4">
          While providing this information is voluntary, including your sexual
          preferences, there's no obligation to do so. Keep in mind that photos
          you upload on Umla can also reveal information about you. By uploading
          sensitive information, including through badges, you're giving
          explicit consent for us to process and share this information publicly
          with other Users.
        </p>

        <p className="mb-4">
          Your Umla profile and other information you make available on the App,
          including data from third-party accounts like Facebook or Instagram
          (see section 7 of this Privacy Policy for more), can be viewed and
          shared by Users, whether they're on the App or not. For instance, a
          User might recommend you as a match to their Facebook friends by
          sharing a screenshot of your profile picture, regardless of whether
          their friend is an App User.
        </p>

        <p className="mb-4">
          If you access the App through a shared device using your Facebook or
          another third-party account, remember to log out of both the App and
          the associated account when you're done to prevent others from
          accessing your Umla account.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          <span>5.</span> Our Policy Towards Age
        </h1>

        <p className="mb-4">
          While we aim for widespread enjoyment of our platform, Umla requires
          users to be at least 18 years old to use the service.
        </p>

        <p className="mb-4">
          Umla does not knowingly gather information from or market to children,
          minors, or anyone below the age of 18. Should we discover that a
          child, minor, or someone under 18 has registered with us and provided
          personal information, we'll take steps to terminate their
          registration.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          <span>6.</span> Security
        </h1>

        <p className="mb-1">
          At Umla, we consider the security of your information a top priority
          and have implemented robust measures to safeguard it against
          unauthorised access, loss, misuse, and disclosure. Our approach
          involves employing reasonable security measures to ensure the
          confidentiality of your personal data, including utilising secure
          servers and firewalls.
        </p>
        <p className="mb-4">
          It's important to acknowledge that, while we strive for the highest
          level of security, no website or online transmission can guarantee
          absolute protection against potential risks such as unauthorised
          access, hacking, data loss, or breaches. Nonetheless, we offer
          practical recommendations to enhance your data's security:
        </p>

        <p className="mb-1">
          Always log out of your Umla account once you've finished your session,
          minimising the chance of unauthorised access.
        </p>
        <p className="mb-1">
          Refrain from sharing your account password with others, as it's a
          crucial element in securing your account.
        </p>

        <p className="mb-1">
          Regularly update your password to strengthen its effectiveness against
          potential threats.
        </p>

        <p className="mb-1">
          If you suspect unauthorized access to your account or password, please
          follow the outlined steps provided here.
        </p>

        <p className="mb-1">
          Be aware that any data transmission to our platform carries inherent
          risks, and it's important to exercise caution.
        </p>
        <p className="mb-1">
          Your security is of utmost importance to us, and while we implement
          stringent security measures, it's essential to remain vigilant in
          safeguarding your personal information.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          <span>7.</span> Linking Other Accounts to Umla
        </h1>

        <p className="mb-2 text-[#646464] font-semibold">
          Integrating Your Social Media Accounts with Umla
        </p>

        <p className="mb-4">
          By signing in to our platform using your social media accounts, such
          as Facebook or Apple ID, you grant permission for certain information
          to be shared with us. If you choose to sign in using your Facebook
          account, Facebook provides us with your name and profile picture.
          Unless you choose otherwise, Facebook may also share your email
          address (if linked to your Facebook account), date of birth, profile
          photos, gender, Page likes, and current location.
        </p>

        <p className="mb-4">
          When you register or sign in through your Apple ID, you give Apple
          consent to share your Apple login, a customizable name, and an email
          (which can be hidden). This email will be associated with your Umla
          account and utilized for account retrieval purposes.
        </p>

        <p className="mb-4">
          This shared information is utilized to establish your Umla account. If
          you remove the Umla app's access from your Facebook or Apple ID
          settings, our access to this data is revoked. Nevertheless, the
          personal data gathered during the initial setup of your Umla account
          using Facebook or Apple ID remains in our possession (full removal of
          your Umla account is required to completely revoke this access).
        </p>

        <p className="mb-2 text-[#646464] font-semibold">
          Linking Social Media to Your Umla Account
        </p>

        <p className="mb-4">
          You have the option to link your Umla account to Instagram, Facebook,
          or Spotify. This integration enables selected information from those
          platforms to be shared directly with your Umla profile, such as
          Instagram photos or top Spotify artists.
        </p>

        <p className="mb-4">
          Only the data that Instagram or Spotify permits to be transferred is
          received by us (subject to Instagram/Facebook/Spotify's terms and your
          consent when linking these accounts to Umla).
        </p>

        <p className="mb-4">
          Should you decide to unlink your Instagram, Facebook, or Spotify
          account from your Umla profile, visit the settings in your respective
          social media account and follow the instructions to revoke the app
          access permission for Umla. Please note that any information
          previously imported to your Umla account from these platforms will
          persist unless you manually remove it within your Umla profile
          settings.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Your Rights
        </h1>

        <p className="mb-4">
          Privacy laws applicable in your country may give you the following
          rights:
        </p>

        <p className="mb-1">
          Right to be informed: what personal data an organization is processing
          and why (we provide this information to you in this Privacy Policy).
        </p>

        <p className="mb-1">
          Right of access: you can request a copy of your data.
        </p>
        <p className="mb-1">
          Right of rectification: if the data held is inaccurate, you have the
          right to have it corrected.
        </p>
        <p className="mb-1">
          Right to erasure: you have the right to have your data deleted in
          certain circumstances.
        </p>
        <p className="mb-1">
          Right to restrict processing: in limited circumstances, you have the
          right to request that processing is stopped but the data retained.
        </p>
        <p className="mb-1">
          Right to data portability: you can request a copy of certain data in a
          machine-readable form that can be transferred to another provider.
        </p>
        <p className="mb-1">
          Right to object: in certain circumstances (including where data is
          processed on the basis of legitimate interests or for the purposes of
          marketing) you may object to that processing.
        </p>
        <p className="mb-1">
          Rights related to automated decision-making including profiling: there
          are several rights in this area where processing carried out on a
          solely automated basis results in a decision which has legal or
          significant effects for the individual. In these circumstances your
          rights include the right to ensure that there is human intervention in
          the decision-making process
        </p>
        <p className="mb-4">
          The particular rights which are applicable to you (which might include
          other rights not listed above) may vary depending on your country. You
          should make yourself aware of the rights you have under applicable
          privacy laws in your country.
        </p>

        <p className="mb-1">
          If you want to exercise any of your rights listed above please visit
          our Feedback Page or email our Data Protection Officer at
          <a href="mailto:DPO@umla.com" className="a" target="_blank">
            {" "}
            DPO@Umla.com.
          </a>
        </p>

        <p className="mb-4">
          For your protection and the protection of all of our Users, we may
          need to request specific information from you to help us confirm your
          identity before we can answer the above requests.
        </p>

        <p className="mb-1">
          If you have a concern about how we have processed your request or your
          personal data, you should contact us in the first instance via the
          contact details listed above.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Data Locations with Umla
        </h1>

        <p className="mb-4">
          We aim to make Umla accessible to you no matter where you are in the
          world. To achieve this, we maintain a global network of servers, which
          includes locations in India, and some other countries. While these
          servers are situated in third-party data centres, they are owned by
          the Umla Group.
        </p>
        <p className="mb-4">
          It's worth noting that data collected by Advertising Partners and
          other Service Providers might also be stored outside of India. To
          ensure the protection of this data, we have put in place appropriate
          safeguards. These safeguards include India approved model clauses,
          which can be accessed here, as well as robust contractual standards.
        </p>

        <p className="mb-4">
          If you require further information about the specifics of the
          safeguards we've implemented, please don't hesitate to contact us at
          <a href="mailto:DPO@umla.com" className="a" target="_blank">
            {" "}
            DPO@Umla.com.
          </a>
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Data Retention and Deletion with Umla
        </h1>

        <p className="mb-4">
          We retain your personal information only for as long as it is
          necessary based on the legal basis described in Section 2 above and as
          allowed by applicable laws. Please refer to "Profile Verification
          Information (Including Biometric Information)" in Section 1 above for
          details on our retention policy regarding biometric data.
        </p>

        <p className="mb-4">
          Upon deletion of your Umla account, we ensure that your account
          becomes no longer visible within the app. However, for a period of up
          to 28 days following deletion, there's a possibility to restore your
          account in case of accidental deletion. After this 28-day period, we
          initiate the process of removing your personal data from our systems,
          unless:
        </p>

        <p className="mb-1">
          We are legally obligated to retain it, such as for tax and accounting
          purposes if you made purchases within the app.
        </p>
        <p className="mb-1">
          We need to retain it to demonstrate our compliance with applicable
          laws, such as retaining account information and records of behavior
          that led to an account block for evidential purposes in case of
          queries or legal claims.
        </p>
        <p className="mb-1">
          There are unresolved issues, claims, or disputes that necessitate the
          retention of relevant information.
        </p>
        <p className="mb-1">
          The information must be retained for our legitimate business
          interests, such as fraud prevention and enhancing user safety and
          security.
        </p>
        <p className="mb-4">
          A note of caution: Even after you delete information from your profile
          or close your Umla account, copies of that information might still be
          accessible or viewable if it was previously shared with others or
          copied/stored by others. We don't have control over this and don't
          accept liability for it. If you've granted third-party applications or
          websites access to your personal data, they might retain such data in
          accordance with their terms of service or privacy policies.
        </p>
        <p className="mb-4">
          Thanks for taking the time to read this, and we hope we didn't bore
          you!
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Changes to this Policy with Umla
        </h1>

        <p className="mb-4">
          As Umla continues to develop, there might be updates to this Privacy
          Policy from time to time. The most current version of the policy will
          govern how we utilise your information and can always be accessed at
          umla.com/privacy. If there's a change to this policy that we deem, at
          our sole discretion, to be significant, we will inform you. This could
          be through an email to the address linked with your account or by
          posting a notice within Umla.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Effective Date
        </h1>

        <p className="mb-4">
          This Privacy Policy was last updated on July 30th, 2023.
        </p>

        <h1 className=" text-3xl font-bold text-black mt-5 mb-4">
          Delete Profile
        </h1>

        <button style={{backgroundColor: '#6D12FF' , color: 'white', padding: '10px', borderRadius:'rounded-sm'}}>Delete Profile</button>
      </div>
    </div>
  );
};

export default Privacy;
