import React from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import dot from "../images/dot.svg";
import logofooter from "../images/logofooter.svg";
import social1 from "../images/social1.svg";
import social2 from "../images/social2.svg";
import social3 from "../images/social3.svg";

function Footer() {
  return (
    <div className="footer-container" style={{ background: "#6D12FF", color: "#FFFFFF", marginTop: "0px" }}>
      <div className="px-5 pt-4 pb-2 d-flex justify-content-between align-items-center">
        <div className="Link" style={{ width: "33vw" }}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <a href="">Services</a>
          <a href="/contact">Get in touch</a>
          <a href="">FAQs</a>
        </div>
        <div className="Logo" style={{ textAlign: "center", width: "33vw" }}>
          <img className="logoFooter" src={logofooter} alt="logo" />
          <div className="offer ">
              

            <span>
              Offer <img className="dotimg" src={dot} alt="." /> Match{" "}
              <img className="dotimg" src={dot} alt="." /> Meet
            </span>
          </div>
        </div>
        <div className="Icons d-flex justify-content-end align-items-end">
          <a href="https://www.instagram.com/umla_app?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
            <img src={social2} alt="." />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100093189796188&mibextid=9R9pXO">
            <img src={social1} alt="." />
          </a>
          <a href="https://www.linkedin.com/company/umlaworld/">
            <img src={social3} alt="." />
          </a>
        </div>
      </div>
      <hr style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }} />
      <div className="py-2 d-flex align-items-center justify-content-center">
        <p>Copyright © 2023 All Rights Are Reserved </p>
      </div>
      {/* <div className='row' style={{ background: "#6D12FF", color: "#FFFFFF" }}>
      <div className='col'>
          <a href="">Home</a>
          <a href="">About</a>
          <a href="">Services</a>
          <a href="">Get in touch</a>
          <a href="">FAQs</a>
      </div>
      <div className='col'>
          <img src={logofooter} alt="logo" />
          <div>OFFER MATCH MEET</div>
      </div>
      <div className='col'>
         <img src={social1} alt="." />
         <img src={social2} alt="." />
         <img src={social3} alt="." />
      </div>
    </div> */}
    </div>
  );
}

export default Footer;
