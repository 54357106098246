import React from 'react';
import '../css/restaurant.css';
import restaurantimg1 from '../images/restaurantimg1.jpg';
import restaurantimg2 from '../images/restaurantimg2.jpg';
import restaurantimg3 from '../images/restaurantimg3.jpg';
import line from '../images/line.svg';
import rightdash from '../images/rightdash.png';
import restaurantgroup2 from '../images/restaurantgroup2.jpg';
import restaurant from '../images/restaurant.jpg'
import mockup4 from '../images/mockup4.png'

function Restaurants() {

    return (
        <>
         <div className='d-flex justify-content-center align-items-end' style={{ marginTop: "68px", backgroundImage: `url(${restaurantgroup2})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition:"center",height: "91vh" }}>
                <div style={{ background: "white", fontSize: "40px", fontWeight: "800", color: "#6D12FF", borderRadius: "20px", padding: "10px 30px", width: "900px", textAlign: "center", marginBottom: "110px" }}>RESTAURANTS</div>
            </div>
            {/* background: linear-gradient(275.12deg, #260062 15.95%, rgba(109, 18, 255, 0) 105.52%); */}


            <div className="RestaurantOnboard mt-5 d-flex justify-content-around align-items-center"
         style={{
          backgroundImage: `linear-gradient(275.12deg, #260062 15.95%, rgba(109, 18, 255, 0) 105.52%),url(${restaurant}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginTop: "100px",
          marginBottom: "100px",
          position: "relative",
        }}
      >
        <div className="mockup4Parent">
          <img className="mockup4" src={mockup4} alt="." />
        </div>
        <div className="restaurantOnboardWrapper py-5 d-flex flex-column align-items-start">
          <h1 style={{color:"#fff",marginTop:"-40px" ,marginBottom:"10px"}}>Get in touch</h1>
          <span style={{fontSize:"22px",fontWeight:"500",color:"#fff",marginTop:"-17px",marginBottom:"10px"}}>Join Forces with UMLA for Greater Reach</span>
          <label className='restaurantlabel' htmlFor="">Restaurant Name</label>
          <input type="text" placeholder='Name'/>
          <label className='restaurantlabel' htmlFor="">Phone Number</label>
          <input type="text" placeholder='+91-1234567890'/>
          <label className='restaurantlabel' htmlFor="">Email Id</label>
          <input type="email" placeholder='example@gmail.com'/>
          <div style={{marginTop:"20px",marginBottom:"-20px"}}>
            <button className='restaurantbutton1'>Clear</button>
            <button className='restaurantbutton2'>Register</button>
          </div>
        </div>
      </div>

            <div className='mt-5 d-flex flex-wrap align-items-center justify-content-around'>
                <div className='ordering'>
                    <p className='Res-Para1' style={{ fontSize: "40px", fontWeight: "700" }}>Get Started With Online Ordering</p>
                    <p style={{ fontSize: "22px", color: "#595959" }}>Please keep the documents ready for a smooth signup</p>
                    <div className='List d-flex justify-content-between align-items-center'>
                        <ul>
                            <li>FSSAI license copy</li>
                            <li>PAN Card copy</li>
                            <li>Regular GSTIN (if applicable)</li>
                        </ul>
                        <ul>
                            <li>Bank account details</li>
                            <li>Your restaurant menu</li>
                            <li>Dish images for top 5 items</li>
                        </ul>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div>
                        <img className='restaurantImg1' src={restaurantimg1} alt="." /> <br />
                        <img className='restaurantImg2' src={restaurantimg2} alt="." />
                    </div>
                    <img className='restaurantImg3' src={restaurantimg3} alt="." />
                </div>
            </div>

            <div className='howItWorks d-flex flex-column justify-content-center align-items-center'>
                <p>How it Works </p>
                <img style={{ marginTop: "-12px", width: "150px" }} src={line} alt="." />
            </div>
            <div className='stepsWrapper d-flex align-items-center justify-content-center'>
                <div className='steps' id='step1'>
                    <p className='step'>01</p>
                    <p style={{ fontSize: "23px", fontWeight: "600" }}>Increased Footfall and Visibility </p>
                    <img className='line-rt' style={{ marginTop: "8px", width: "150px", marginLeft: "30px"}} src={line} alt="." />
                    <p className='stepDesc'>Users buying experiences like coffee, Beer and select the restaurant as their meet-up location, boosting footfall and introducing the establishment to potential customers.</p>
                </div>
                <img className='arrow' src={rightdash} alt="." />
                <div className='steps' id='step2' style={{marginTop:"120px"}}>
                    <p className='step'>02</p>
                    <p style={{ fontSize: "23px", fontWeight: "600" }}>Targeted Matching and Customer Engagement</p>
                    <img className='line-rt' style={{ marginTop: "8px", width: "150px",marginLeft: "30px" }} src={line} alt="." />
                    <p className='stepDesc'>UMLA's algorithm ensures precise matching based on location and preferences. This  increases the likelihood of users choosing the collaborating restaurant for their meet-up, fostering customer engagement and repeat business.</p>
                </div>
                <img className='arrow' src={rightdash} alt="." />
                <div className='steps' id='step3' style={{marginTop:"80px"}}>
                    <p className='step'>03</p>
                    <p style={{ fontSize: "23px", fontWeight: "600" }}>Enhanced User Experience and Brand Exposure</p>
                    <img className='line-rt' style={{ marginTop: "8px", width: "150px" ,marginLeft: "30px" }} src={line} alt="." />
                    <p className='stepDesc'>Through meet-ups facilitated by UMLA, restaurants gain exposure to a wider audience, as users share their experiences on social media, attracting potential customers.</p>
                </div>
            </div>
        </>
    )
}

export default Restaurants


