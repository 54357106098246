import React from 'react'
import dot from "../Assets/UmlaWebsite/dot.svg";
import social1 from "../Assets/UmlaWebsite/social1.svg";
import social3 from "../Assets/UmlaWebsite/social3.svg";
import social2 from "../Assets/UmlaWebsite/social2.svg";
import badge from "../Assets/UmlaWebsite/Badge.png";
import frame2 from "../Assets/UmlaWebsite/frame2.png";
import card1 from "../Assets/UmlaWebsite/card1.png";
import card2 from "../Assets/UmlaWebsite/card2.png";
import pngwing from "../Assets/UmlaWebsite/pngwing.png";
import french from "../Assets/UmlaWebsite/french.png";
import pngwing2 from "../Assets/UmlaWebsite/pngwing2.png";
import sandwich from "../Assets/UmlaWebsite/sandwich.png";
import frame3 from "../Assets/UmlaWebsite/frame3.png";
import group1 from "../Assets/UmlaWebsite/Group1.png";
import group2 from "../Assets/UmlaWebsite/Group2.png";
import like from "../Assets/UmlaWebsite/like.png";
import interest from "../Assets/UmlaWebsite/interest.png";

import frame3img from "../Assets/UmlaWebsite/frame3img.png";
import mockup4 from "../Assets/UmlaWebsite/mockup4.png";
import line from '../images/line.svg';




import logofooter from "../images/logofooter.svg";

import '../css/homemobile.css'
import { Link } from 'react-router-dom';

function HomeMobile() {
    return (
        <div className="home-page">
            <div className="frame1">
                <div className="background-image"></div>
                <div className="content">
                    <h1 className="title">
                        Where Offer <br />
                        Meets Matches
                    </h1>
                    <div className="Offer" style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            OFFER <img className="dotimg" src={dot} alt="." /> MATCH{" "}
                            <img className="dotimg" src={dot} alt="." /> MEET
                        </div>
                    </div>



                    <a
                        className="store-link"
                        href="https://play.google.com/store/apps/details?id=com.umlaa.app"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="button">Download Umla</button>
                        <img src={badge} alt="Get it on Play Store" />
                    </a>
                    <div className="Social-media-links" style={{ display: "flex", justifyContent: "center" }}>
                        <a href="https://www.instagram.com/umla_app?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                            <img src={social2} alt="Instagram" />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100093189796188&mibextid=9R9pXO">
                            <img src={social1} alt="Facebook" />
                        </a>
                        <a href="https://www.linkedin.com/company/umlaworld/">
                            <img src={social3} alt="LinkedIn" />
                        </a>
                    </div>

                </div>
            </div>
            <div className="frame2">
                <div className="background-img2">
                    <div className="text">
                        <p style={{ textShadow: '2px 2px 8px #000000', marginTop: "20px", marginBottom: "20px" }}>How it Works</p>
                        <span>Create Offer</span>
                        <img style={{ marginTop: "-5px", width: "140px", marginLeft: '15px' }} src={line} alt="." />


                    </div>
                    <div className="card-container">
                       

                        <img className="png-img1" src={pngwing} alt="" />
                        <img className="fr2-img" src={frame2} alt="" />
                        <img className="card1-img" src={card1} alt="Card 1" />
                        <img className="card2-img" src={card2} alt="" />
                        <img className="png-img2" src={pngwing2} alt="" />
                        <img className="png-french" src={french} alt="" />

                    </div>
                </div>
            </div>
            <div className="frame3">

                <div className="background-img2">
                    <section className=''>
                        <p style={{ marginBottom: "20px" }}>
                            
                            Create Instant or Schedule Meetup  <br />  <strong style={{ textShadow: '2px 2px 15px #000000' }}>Offer</strong>
                            <div className='SlidingVertical'>
                                <span>Coffee.</span>
                                <span>Beer.</span>
                                <span>Movie.</span>
                                <span>Trip.</span>
                            </div>
                      
                            <br />
                            to people with similar
                            interest and hobbies to get a perfect match
                        </p>
                    </section>
                    <span style={{ marginTop: "10px", marginBottom: "-20px" }}>Match
                        <img style={{ marginTop: "-5px", width: "90px", marginLeft: '0px' }} src={line} alt="." />
                    </span>

                    <div className="card-container2">
                        <div>
                            <img className="group1" src={group1} alt="" />
                            <img className="interest-png" src={interest} alt="" />
                        </div>
                        <div>
                            <img className="fr3-img" src={frame3} alt="" />
                        </div>
                        <div>
                            <img className="like-png" src={like} alt="" />

                            <img className="group2" src={group2} alt="" />
                        </div>

                    </div>
                    <div className="MeetWrapper">
                        <p className="Meet1">MATCH!</p>
                        <span className="Meet2">MATCH!</span>
                    </div>

                </div>
            </div>
            <div className="frame4">
                <div className="background-img2">
                    <p>
                        Get match Instantly after sending offer to people

                        with similar
                        interest and hobbies and meet them physically
                    </p>
                    <span>Meet
                        <img style={{ marginTop: "-5px", width: "90px", marginLeft: '0px' }} src={line} alt="." />
                    </span>
                    <div className="card-container3">
                        <div className="img-container">
                            <img className="fr4-img" src={frame3img} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame5">
                <div className="background-img3">
                    <div className="fr5-para">
                        <p>
                            From Digital match to Real meet ! Meet with matched person in less
                            than 24 Hours and make new friends
                        </p>

                    </div>

                    <div className="bg">
                        <div className="restaurant"></div>
                        <div className="res-content">
                            <div className="left">
                                <img className="mockup-img" src={mockup4} alt="" />
                            </div>
                            <div className="right">
                                <div className="res-text">
                                    <p>
                                        Register your restaurant/cafe <br /> on UMLA
                                    </p>
                                    <span>And get more customers and recommendations!</span>
                                </div>
                                <button>Register Your Restaurant</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default HomeMobile