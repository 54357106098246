import React, { useState } from "react";
import "../css/contact.css";
  
function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    // You can add code here to send the form data to your server or perform other actions.
  };

  return (
    <div>
      <div className="contact-us ">
        <div className="heading flex justify-center font-bold text-3xl text-white  border border-black p-4">
          Contact Us
        </div>
        <div className="info flex justify-center items-center text-3xl flex-wrap ">
          <h1>For any queries contact us at: </h1>
          <a
            href="mailto: umlahelp@gmail.com"
            className="text-3xl text-red-500"
          >
            {" "}
            umlahelp@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
