import React from 'react'
import '../css/blogcard.css'
import { Link } from 'react-router-dom'

function BlogCard(props) {
    console.log(props);
    return (
        <>
            <div className="blog card">
                <img style={{width:"100%",height:"280px"}} className="card-img-top p-1" src={props.image} alt="." />
                <div className="card-body">
                    <h6 style={{ fontWeight: "600", color: "#232233", textTransform: "uppercase", marginTop: "15px" }}>{props.title}</h6>
                    <p style={{ fontSize: "12px", color: "#6C6C72", marginTop: "30px" }}>{props.content}</p>
                    <Link style={{ color: "#6D12FF", fontSize: "15px", fontWeight: "600", marginTop: "50px" }} to=
                    
                    // {props.link}
                    
                    {`/blog/${props.id}`}
                     >READ MORE</Link>
                </div> 
            </div>
        </>
    )
}

export default BlogCard