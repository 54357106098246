import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/blogs.css';
import blogbackground from '../images/blogbackground.jpg';
import BlogCard from './BlogCard';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch('https://payment.umla.in/api/v1/umla/user/getBlogs', {    // Adding method type 
    method: "GET", 
      
    // // Adding body or contents to send 
    // body: JSON.stringify({ 
    //     title: "foo", 
    //     body: "bar", 
    //     userId: 1 
    // }), 
      
    // Adding headers to the request 
    headers: { 
        "Content-type": "application/json; charset=UTF-8"
    }})
      .then(response => {
        console.log(response );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setBlogs(data);
      })
      .catch(error => {
        console.error('There was an error fetching the blogs!', error);
      });
  }, []);
  return (
    <>
      <div className='d-flex justify-content-center align-items-end' style={{ marginTop: "68px", backgroundImage: `url(${blogbackground})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition:"center",height: "91vh" }}>
        <div style={{ background: "white", fontSize: "40px", fontWeight: "800", color: "#6D12FF", borderRadius: "20px", padding: "10px 30px", width: "900px", textAlign: "center", marginBottom: "110px" }}>OUR BLOGS</div>
      </div>
      <div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
        <p style={{fontSize:"30px",fontWeight:"600"}}>All Articles</p>
        <p className='articlesDesc'>
          Ready to expand your horizons and connect with like-minded individuals across industries and interests? Join [umla networking app] – the ultimate destination for professionals, entrepreneurs, travelers, movie buffs, outdoor enthusiasts, and more. Sign up today and embark on a journey of meaningful connections, shared experiences, and endless possibilities!
        </p>
      </div>
      <div className='p-5 mt-1 d-flex flex-wrap gap-4 justify-content-center align-items-center'>
        {

          blogs.map((data, i) => (
          <BlogCard key={i}
           id= {data._id}
            title={data.title}
            image={data.image}
            content={data.content}
            link={data.link}
          />
        ))}
      </div>
    </>
  );
}

export default Blogs;
