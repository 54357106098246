import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Blogs from "./Components/Blogs";
import Restaurants from "./Components/Restaurants";
import Blog from "./Components/Blog";
import FullPage from "./pages/registrationForm/FullPage";
import Privacy from "./Components/Privacy";
import ContactUs from "./Components/ContactUs";
import DeleteProfile from "./Components/deleteProfile";
import PaymentDetails from "./Components/PaymentDetails";
import { Outlet } from "react-router-dom";
import TermsAndCon from "./Components/TermsAndCon";
import Blog2 from "./Components/Blog2";
import Blog3 from "./Components/Blog3";
import Blog4 from "./Components/Blog4";
import Blog5 from "./Components/Blog5";
import Blog6 from "./Components/Blog6";
import Blog7 from "./Components/Blog7";
import Blog8 from "./Components/Blog8";
import ScrollToTop from "./ScrollToTop";

function Layout() {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Navbar />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id" element={<Blog />} />
          {/* <Route path="/blog/2" element={<Blog2 />} />
          <Route path="/blog/3" element={<Blog3 />} />
          <Route path="/blog/4" element={<Blog4 />} />
          <Route path="/blog/5" element={<Blog5/>} />
          <Route path="/blog/6" element={<Blog6/>} />
          <Route path="/blog/7" element={<Blog7/>} />
          <Route path="/blog/8" element={<Blog8/>} /> */}




          



          <Route path="/contact" element={<ContactUs />} />
          <Route path="/deleteProfile" element={<DeleteProfile />} />
          <Route path="/restaurants" element={<Restaurants />} />
          <Route path="/register" element={<FullPage />} />
          <Route path="/redirectUrl" element={<PaymentDetails />} />
        </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/termsAndConditions" element={<TermsAndCon />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
